





import { Component, Prop, Vue } from 'vue-property-decorator';
import { GetPhoneVerifyCodeV2 } from '@/api/api';
import { appConfigModule } from '@/store/modules/appConfig';

@Component({
    name: 'VerificationCode',
    components: {}
})
export default class VerificationCode extends Vue {
    @Prop({ type: String, default: '' }) private phone: any;

    @Prop({ type: String, default: '' }) private CountryIdCode: any;

    @Prop({ type: String, default: '' }) public VerifyCode: any;

    @Prop({ type: String, default: '' }) private VerifyCookie: any;

    public loading: boolean = false;
    public show: boolean = true;
    public timer: any = null;
    public count: number = 0;

    public async getCode() {
        try {
            if (this.phone === '') {
                const message: any = this.$t('login.register.massage2');
                this.$message(message);
                return;
            }
            this.loading = true;

            const params = {
                Phone: this.phone,
                Code: this.CountryIdCode || 0,
                VerifyCode: this.VerifyCode
            };
            await GetPhoneVerifyCodeV2(params, {
                headers: {
                    Authorization: `Bearer ${this.shopId}`,
                    VerifyCookie: this.VerifyCookie
                }
            });
            const message: any = this.$t('login.register.message3');
            this.$message(message);
            // 倒计时
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                    } else {
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000);
            }
        } catch (err) {
            console.warn(err);
        } finally {
            this.loading = false;
        }
    }

    public get shopId() {
        return appConfigModule.appConfig.shopId;
    }
}
