












import { Component, Prop, Vue } from 'vue-property-decorator';
import { GetVerifyCode } from '@/api/api';

@Component({
    name: 'VerificationCodeImage',
    components: {}
})
export default class VerificationCodeImage extends Vue {
    @Prop({
        type: [String, Number],
        default: () => ''
    })
    public value:any;

    public ImgBase64: string = '';

    private async created() {
        this.getCode();
    }

    public async getCode() {
        try {
            const { Data } = await GetVerifyCode();
            const img = Data.Base64 ? Data.Base64 : Data;
            this.ImgBase64 = 'data:image/png;base64,' + img;
            this.$emit('change', Data);
        } catch (err) {
            console.warn(err);
        }
    }

    public input(value) {
        this.$emit('input', value);
    }

}
