












import { Component, Vue } from 'vue-property-decorator';
import { GetVerifyCode } from '@/api/api';
import { appConfigModule } from '@/store/modules/appConfig';

@Component({
    name: 'ImageVerificationCode',
    components: {}
})
export default class ImageVerificationCode extends Vue {

    public loading: boolean = false;

    public ImgBase64: any = '';

    public created() {
        this.getCode();
    }

    public async getCode() {
        try {
            this.loading = true;
            const { Data } = await GetVerifyCode({
                headers: {
                    Authorization: `Bearer ${this.shopId}`

                }
            });
            this.ImgBase64 = 'data:image/png;base64,' + Data.Base64;
            this.$emit('change', Data.Sign);
        } catch (error) {
            console.warn(error);
        } finally {
            this.loading = false;
        }
    }

    public get shopId() {
        return appConfigModule.appConfig.shopId;
    }
}
