






import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
    name: 'Radio',
    components: {}
})
export default class Radio extends Vue {
    @Prop({ type: Boolean, default: false }) value: false;

    private mounted() {
        // aa
    }
}
