


















import { Component, Vue } from 'vue-property-decorator';

import { EmbeddedScriptList } from '@/api/api';
import { otherModule } from '@/store/modules/other';

@Component({
    name: 'RegisterSuccess',
    components: {}
})
export default class RegisterSuccess extends Vue {
    public data: any = {};

    // 注册来源(淘宝注册:1，LINE注册:2，脸书注册:3, 抖音注册:4，小红书注册:5，TikTok注册:6, 谷歌注册:7，Popln注册:8，网站注册:9,微信公众号注册:10)

    public created() {
        const data = this.$route.query;
        this.data = data;

        // 根据运营的 编号加载 脚本类型
        // fb
        if (this.Source.indexOf('3') !== -1) {
            this.init(3);
            return;
        }

        // line
        if (this.Source.indexOf('2') !== -1) {
            this.init(4);
            return;
        }

        // tik tok
        if (this.Source.indexOf('6') !== -1) {
            this.init(6);
            return;
        }

        // tik popln
        if (this.Source.indexOf('9') !== -1) {
            this.init(9);
        }
    }

    public jump(path) {
        this.$router.push({
            path
        });
    }

    private async init(id) {
        try {
            // 这里的id是脚本类型的id
            const params: any = [{ FieldName: 'ScriptTypeId', FieldValue: id, ConditionalType: 0 }];
            const { DataList } = await EmbeddedScriptList({
                Expressionable: JSON.stringify(params)
            });
            DataList.forEach((item: any) => {
                // 这里只处理 会员注册成功的事件
                if (item.HtmlBody) {
                    const html = this.parseDom(item.HtmlBody);
                    document.body.appendChild(html);
                }
                if (item.ScriptBody) {
                    const script = document.createElement('script');
                    script.async = true;
                    script.innerText = item.ScriptBody;
                    document.head.appendChild(script);
                }
            });
        } catch (err) {
            console.warn(err);
        }
    }

    private parseDom(arg: string) {
        const objE = document.createElement('div');
        objE.innerHTML = arg;
        return objE;
    }

    // 获取来源
    private get Source(): any {
        return otherModule.registeredSource;
    }
}
