










































import { Component, Vue, Ref } from 'vue-property-decorator';

import MobileNumberType from './mobile-number-type.vue';
import YwhzVerificationCode from './VerificationCode.vue';
import ImageVerificationCode from './ImageVerificationCode.vue';

import { validators } from '@/utils/validate';
import { Forget } from '@/api/api';
@Component({
    name: 'ForgotPassword',
    components: {
        YwhzVerificationCode,
        MobileNumberType,
        ImageVerificationCode
    }
})
export default class ForgotPassword extends Vue {
    @Ref('form') private readonly formRef: any;

    public url: string = '';
    public CountryIdCode: any = '';

    public VerifyCookie = '';
    public formData: any = {
        Phone: '',
        Pwd: '',
        CountryId: '',
        VerificationCode: '',
        VerifyCode: ''
    };

    public formRules: any = {
        Phone: [validators.required],
        Pwd: [validators.required],
        VerifyCode: [validators.required],
        VerificationCode: [validators.required]
    };

    public async submit() {
        try {
            await this.formRef.validate();
            await Forget(this.formData);
            const message: any = this.$t('login.forgotPassword.message');
            this.$message(message);
            this.jump('/login/signin');
        } catch (error) {
            console.warn(error);
        }
    }

    public mobileChange(item) {
        this.CountryIdCode = item.add;
    }

    public jump(path) {
        this.$router.push({
            path
        });
    }
}
