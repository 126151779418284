


























import { Component, Vue, Ref } from 'vue-property-decorator';
import VerificationCodeImage from './VerificationCodeImage.vue';

import { validators } from '@/utils/validate';
import { UserModule } from '@/store/modules/user';
import { appConfigModule } from '@/store/modules/appConfig';

@Component({
    name: 'Signin',
    components: {
        VerificationCodeImage
    }
})
export default class Signin extends Vue {
    @Ref('form') private readonly formRef: any;

    public loading: boolean = false;

    public checked: boolean = false;

    public ImgBase64: string = '';

    public dataForm: any = {
        UserId: '',
        PassWord: '',
        VerifyCode: '',
        uuid: '',
        Device: '1'
    };

    public formRules: any = {
        UserId: [validators.required],
        PassWord: [validators.required],
        VerifyCode: [validators.required]
    };

    private created() {
        const { PassWord, UserName } = this.accountPassword;
        if (UserName !== '') {
            this.dataForm.UserId = UserName;
            this.dataForm.PassWord = PassWord;
            this.checked = true;
        }
    }

    public async submit() {
        try {
            this.loading = true;
            await this.formRef.validate();
            await UserModule.Login(this.dataForm);

            // 是否记住密码
            const params = {
                UserName: '',
                PassWord: ''
            };
            if (this.checked) {
                params.UserName = this.dataForm.UserId;
                params.PassWord = this.dataForm.PassWord;
            }
            UserModule.setAccountPassword(params);
            window.location.href = (this.appConfig.vueAdmin + '/admin');
        } catch (error) {
            console.warn(error);
            const codeImage: any = this.$refs.VerificationCodeImage;
            codeImage.getCode();
        } finally {
            this.loading = false;
        }
    }

    public jump(path) {
        this.$router.push({
            path
        });
    }

    private get accountPassword() {
        return UserModule.accountPassword;
    }

    public get appConfig() {
        return appConfigModule.appConfig;
    }
}
