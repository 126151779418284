







































import { Component, Vue, Watch } from 'vue-property-decorator';
import MainLogo from '@/layout/components/logo.vue';

// 登录
import signin from './component/signin.vue';
// 注册
import register from './component/register.vue';
// 忘记密码
import ForgotPassword from './component/forgot-password.vue';

// 注冊成功
import RegisterSuccess from './register-success.vue';

import MainMenu from './component/menu.vue';

@Component({
    name: 'Login',
    components: {
        MainLogo,
        MainMenu,
        register,
        signin,
        ForgotPassword,
        RegisterSuccess
    }
})
export default class Login extends Vue {

    public componentName: string = 'signin'

    private created() {
        this.init();
    }

    private init() {
        const { type } = this.$route.params;
        this.componentName = type;
    }

    public tabClick(e) {
        const name = e.name;
        this.$router.push({
            path: `/login/${name}`
        });
    }

    @Watch('$route')
    private change() {
        this.init();
    }
}
