
















import { Component, Vue } from 'vue-property-decorator';
// https://github.com/Kevin-269581661/vue-puzzle-verification
import PuzzleVerification from 'vue-puzzle-verification';
import { otherModule } from '@/store/modules/other';

@Component({
    name: 'SlideVerification',
    components: {
        PuzzleVerification
    }
})
export default class SlideVerification extends Vue {
    public canvas: any = null;
    // 弹窗显示
    public visible: boolean = false;
    // 验证框显示
    public is: boolean = false;

    public puzzleImgList: any = [
        // appFig.vueSrc + '/banner/banner01.png',
        // appFig.vueSrc + '/banner/banner02.png',
        // appFig.vueSrc + '/banner/banner03.png'
    ];

    public open() {
        this.visible = true;
        this.canvas = null;
        this.bannerArr.forEach(item => {
            this.puzzleImgList.push(item.ImagePatch);
        });
        this.$nextTick(() => {
            this.is = true;
            // 由于这个组件 点击关闭按钮没有回调。这里手动绑定事件
            this.canvas = document.querySelector('.close-btn');
            this.canvas.onclick = () => {
                this.close();
            };
        });
    }

    public close() {
        this.visible = false;
        this.$nextTick(() => {
            this.is = false;
        });
    }

    public onSuccess() {
        this.$emit('onSuccess');
        this.close();
    }

    private get bannerArr() {
        return otherModule.homeBanner;
    }

}
